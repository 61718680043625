import { proxy } from 'valtio';
/**
 * # agent 層級的 root store
 *
 * ## 統一管理這一層次的所有客製值
 */
export const asiajyeStore = proxy({
    /** # 指標「{@link pricetrade}」內部會使用的值 */
    indicatorsSelected: 'none',
    turningTrade: {
        prices: [],
        position: 1,
        priceValue: 0,
        assistPriceValue: 0,
        turningMP: 1,
    },
});
