import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const power = createIndicator({
    displayName: '主力控盤',
    id: 'power',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const c = this.PineJS.Std.close(this._context);
            const nc = this._context.new_var(c);
            const ma20 = this.PineJS.Std.ema(nc, 20, this._context);
            const ma60 = this.PineJS.Std.sma(nc, 60, this._context);
            const diff = ma20 - ma60;
            let val0 = NaN;
            let val1 = NaN;
            if (diff > 0) {
                val0 = diff;
            }
            else
                val1 = diff;
            return [val0, val1];
        },
    },
    metainfo: {
        is_price_study: !1,
        _metainfoVersion: 42,
        isTVScript: !1,
        isTVScriptStub: !1,
        is_hidden_study: !1,
        plots: [
            { id: 'plot_0', type: 'line' },
            { id: 'plot_1', type: 'line' },
        ],
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 0,
                    linewidth: 5,
                    plottype: 8,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#d75442',
                },
                plot_1: {
                    linestyle: 0,
                    linewidth: 5,
                    plottype: 8,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#009900',
                },
            },
            precision: 2,
            inputs: {},
        },
        styles: {
            plot_0: {
                title: '+力道',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_1: {
                title: '-力道',
                histogramBase: 0,
                joinPoints: !1,
            },
        },
        inputs: [],
    },
});
