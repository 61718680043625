/* eslint-disable no-var, no-console */
import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
import { useIndicatorStore } from '~/store/useIndicatorStore';
import { useSoundStore } from '~/modules/SDK/sound/useSoundStore';
const sounds = useSoundStore.getState();
let exit_sound_flag_ = 0;
let entry_sound_flag_ = 1;
export const bandtrade = createIndicator({
    id: 'bandtrade',
    displayName: '探極波段',
    constructorScope: {
        init(context, inputCallback) {
            localStorage.setItem('signalInitializedTimestamp', new Date().getTime().toString());
        },
        main(context, inputCallback) {
            const state = useIndicatorStore.getState();
            const high = this.PineJS.Std.high(this._context);
            const high_array = this._context.new_var(high);
            const daily_high = this._context.new_var();
            const low = this.PineJS.Std.low(this._context);
            const low_array = this._context.new_var(low);
            const daily_low = this._context.new_var();
            const close = this.PineJS.Std.close(this._context);
            const close_array = this._context.new_var(close);
            const itime = this.PineJS.Std.time(this._context);
            const start_h = new Date(itime).getHours();
            const start_m = new Date(itime).getMinutes();
            const pc_high = this._context.new_var();
            const pc_low = this._context.new_var();
            const bState = this._context.new_var();
            const bsentry = this._context.new_var();
            const bDt = this._context.new_var();
            const bExitSound = this._context.new_var();
            var pv0 = NaN;
            var pv1 = NaN;
            var pvflag = this._context.new_var();
            var entryPrice = this._context.new_var();
            var pb1 = NaN;
            var pb2 = NaN;
            var pb3 = NaN;
            var ps1 = NaN;
            var ps2 = NaN;
            var ps3 = NaN;
            if (start_h === 8 && start_m === 45) {
                daily_high.set(high);
                daily_low.set(low);
                bsentry.set(0);
                if (pvflag.get(0) === 3) {
                    bState.set(0);
                }
            }
            if (high > daily_high) {
                daily_high.set(high);
            }
            if (low < daily_low) {
                daily_low.set(low);
            }
            if (start_h === 12 && start_m === 45) {
                pc_high.set(daily_high * 1);
                pc_low.set(daily_low * 1);
            }
            bState.get(1);
            bState.get(2);
            bExitSound.get(1);
            low_array.get(1);
            high_array.get(1);
            /** 部位確立 */
            if (start_h > 8) {
                if (close > pc_high && bsentry.get(0) <= 1) {
                    bState.set(1);
                    bDt.set(itime);
                    bExitSound.set(0);
                }
                else if (close < pc_low && bsentry.get(0) <= 1) {
                    bState.set(-1);
                    bDt.set(itime);
                    bExitSound.set(0);
                }
            }
            /** 訊號出訊至圖表 */
            if (bState.get(0) === 1 && bState.get(1) !== 1 && bsentry.get(0) <= 2) {
                pv0 = 1;
                pv1 = NaN;
                pvflag.set(0);
                bsentry.set(bsentry + 1);
                entryPrice.set(close);
                //state.entryPrice = Number(open)
                //state.marketPosition = Number(1)
            }
            else if (bState.get(0) === -1 && bState.get(1) !== -1 && bsentry.get(0) <= 2) {
                pv0 = NaN;
                pv1 = 1;
                pvflag.set(0);
                bsentry.set(bsentry + 1);
                entryPrice.set(close);
                //state.entryPrice = Number(open)
                //state.marketPosition = Number(-1)
            }
            var p1 = 0.01;
            var p2 = 0.02;
            var p3 = 0.03;
            if (high > entryPrice * (1 + 0.02) && start_h === 12 && start_m === 30) {
                bState.set(0);
            }
            if (low < entryPrice * (1 - 0.02) && start_h === 12 && start_m === 30) {
                bState.set(0);
            }
            /** 停利停醒 */
            if (bState.get(0) === 1) {
                if (pvflag.get(0) === 0 && high > entryPrice * (1 + p1)) {
                    pvflag.set(1);
                    pb1 = 1;
                    bExitSound.set(1);
                }
                else if (pvflag.get(0) === 1 && high > entryPrice * (1 + p2)) {
                    pvflag.set(2);
                    pb2 = 1;
                    bExitSound.set(2);
                }
                else if (pvflag.get(0) === 2 && high > entryPrice * (1 + p3)) {
                    pvflag.set(3);
                    pb3 = 1;
                    bExitSound.set(3);
                }
            }
            else if (bState.get(0) === -1) {
                if (pvflag.get(0) === 0 && low < entryPrice * (1 - p1)) {
                    pvflag.set(1);
                    ps1 = 1;
                    bExitSound.set(1);
                }
                else if (pvflag.get(0) === 1 && low < entryPrice * (1 - p2)) {
                    pvflag.set(2);
                    ps2 = 1;
                    bExitSound.set(2);
                }
                else if (pvflag.get(0) === 2 && low < entryPrice * (1 - p3)) {
                    pvflag.set(3);
                    ps3 = 1;
                    bExitSound.set(3);
                }
            }
            //聲音------------------------------------------------------------------
            const displayTime = new Date(itime).toLocaleTimeString('en-US');
            const localTimeVar = this._context.new_var();
            const timeDeltaMs = 500;
            const kbarLoadingTimeMs = 10000;
            const kbarIntervalMs = this.PineJS.Std.interval(this._context) * 60 * 1000;
            const isInitialized = new Date().getTime() >
                parseInt(localStorage.getItem('signalInitializedTimestamp') || 'NaN') + kbarLoadingTimeMs;
            const isBarChanging = isNaN(localTimeVar.get(1)) ||
                Math.abs(new Date().getTime() - localTimeVar.get(1)) < timeDeltaMs;
            localTimeVar.set(new Date().getTime());
            /** 進場部位聲音 */
            if (isInitialized) {
                //防止無訊號時出現聲音提醒 當出現新的進場成立flag會被歸零
                //entry_sound_flag_ = 1
            }
            if (isInitialized && isBarChanging) {
                if (bState.get(1) === 1 &&
                    bState.get(2) !== 1 &&
                    isNaN(bState.get(2)) === false &&
                    entry_sound_flag_ === 0) {
                    sounds.playSoundAsiajye2Debounced();
                    entry_sound_flag_ = 1;
                    console.log('✅', displayTime, '探極波段進多單');
                }
                else if (bState.get(1) === -1 &&
                    bState.get(2) !== -1 &&
                    isNaN(bState.get(2)) === false &&
                    entry_sound_flag_ === 0) {
                    sounds.playSoundAsiajye2Debounced();
                    entry_sound_flag_ = 1;
                    console.log('✅', displayTime, '探極波段進空單');
                }
            }
            //出場部位聲音
            if (isInitialized && isBarChanging) {
                exit_sound_flag_ = 0;
            }
            const long_exit_condition = (pb1 === 1 && exit_sound_flag_ !== 1) ||
                (pb2 === 1 && exit_sound_flag_ !== 1) ||
                (pb3 === 1 && exit_sound_flag_ !== 1);
            const short_exit_condition = (ps1 === 1 && exit_sound_flag_ !== 1) ||
                (ps2 === 1 && exit_sound_flag_ !== 1) ||
                (ps3 === 1 && exit_sound_flag_ !== 1);
            if (isInitialized) {
                if (long_exit_condition) {
                    sounds.playSoundAsiajye1Debounced();
                    exit_sound_flag_ = 1;
                    console.log('🚨', displayTime, '探極波段停利');
                }
                if (short_exit_condition) {
                    sounds.playSoundAsiajye1Debounced();
                    exit_sound_flag_ = 1;
                    console.log('🚨', displayTime, '探極波段停利');
                }
                const info = this.logPositionInfo(bState.get(0), pvflag.get(0), entry_sound_flag_, exit_sound_flag_);
                console.log(info, bState.get(0), bState.get(1), bState.get(2));
            }
            //indicatorStoreSetStateDebounced(state)
            return [
                this.PineJS.Std.sma(pc_high, 1, this._context),
                this.PineJS.Std.sma(pc_low, 1, this._context),
                pv0,
                pv1,
                pb1,
                pb2,
                pb3,
                ps1,
                ps2,
                ps3,
            ];
        },
    },
    metainfo: {
        _metainfoVersion: 27,
        isTVScript: !1,
        isTVScriptStub: !1,
        is_hidden_study: !1,
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 6,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#ff0000',
                },
                plot_1: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 6,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#00aa00',
                },
                plot_2: {
                    color: '#ff0000',
                    textColor: '#ff0000',
                    transparency: 20,
                    visible: true,
                },
                plot_3: {
                    color: '#00bb00',
                    textColor: '#00aa00',
                    transparency: 20,
                    visible: true,
                },
                plot_4: {
                    color: '#aaaa00',
                    textColor: '#aaaa00',
                    transparency: 20,
                    visible: true,
                },
                plot_5: {
                    color: '#aa5500',
                    textColor: '#aa5500',
                    transparency: 20,
                    visible: true,
                },
                plot_6: {
                    color: '#aa2222',
                    textColor: '#aa2222',
                    transparency: 20,
                    visible: true,
                },
                plot_7: {
                    color: '#0aa',
                    textColor: '#0aa',
                    transparency: 20,
                    visible: true,
                },
                plot_8: {
                    color: '#4444aa',
                    textColor: '#4444aa',
                    transparency: 20,
                    visible: true,
                },
                plot_9: {
                    color: '#000aaa',
                    textColor: '#000aaa',
                    transparency: 20,
                    visible: true,
                },
            },
        },
        plots: [
            {
                id: 'plot_0',
                type: 'line',
            },
            {
                id: 'plot_1',
                type: 'line',
            },
            {
                id: 'plot_2',
                type: 'chars',
            },
            {
                id: 'plot_3',
                type: 'chars',
            },
            {
                id: 'plot_4',
                type: 'chars',
            },
            {
                id: 'plot_5',
                type: 'chars',
            },
            {
                id: 'plot_6',
                type: 'chars',
            },
            {
                id: 'plot_7',
                type: 'chars',
            },
            {
                id: 'plot_8',
                type: 'chars',
            },
            {
                id: 'plot_9',
                type: 'chars',
            },
        ],
        styles: {
            plot_0: {
                title: 'Plot',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_1: {
                title: 'Plot',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_2: {
                isHidden: false,
                location: 'BelowBar',
                char: '▲',
                size: 'small',
                text: 'B',
            },
            plot_3: {
                isHidden: false,
                location: 'AboveBar',
                char: '▼',
                size: 'small',
                text: 'S',
            },
            plot_4: {
                isHidden: false,
                location: 'AboveBar',
                char: '✪',
                size: 'small',
                title: 'Shapes',
            },
            plot_5: {
                isHidden: false,
                location: 'AboveBar',
                char: '✪',
                size: 'normal',
                title: 'Shapes',
            },
            plot_6: {
                isHidden: false,
                location: 'AboveBar',
                char: '☻',
                size: 'large',
                title: 'Shapes',
            },
            plot_7: {
                isHidden: false,
                location: 'BelowBar',
                char: '✪',
                size: 'small',
                title: 'Shapes',
            },
            plot_8: {
                isHidden: false,
                location: 'BelowBar',
                char: '✪',
                size: 'normal',
                title: 'Shapes',
            },
            plot_9: {
                isHidden: false,
                location: 'BelowBar',
                char: '☻',
                size: 'large',
                title: 'Shapes',
            },
        },
        is_price_study: !0,
        inputs: [],
        scriptIdPart: '',
    },
});
